import React, { useState } from "react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useDatabase } from "./service/data.service";

const Login = ({ setIsAuth }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const signIn = useSignIn();
  const { login } = useDatabase();

  function handleClick(e) {
    e.preventDefault();
    login(username, password)
      .then((response) =>
        response.json().then((data) => {
          console.log("Réponse de la requête de login : ", data);
          if (
            signIn({
              auth: {
                token: data.token,
                type: "Bearer",
              },
              userState: data.authUserState,
            })
          ) {
            console.log("Utilisateur connecté : ", data.authUserState.user_id);
            setIsAuth(true);
          } else {
            console.log("Erreur dans l'authentification de la page");
          }
        })
      )
      .catch((error) => {
        console.log("Erreur dans l'authentification");
      });
    // if () {
    //   setIsAuth(true);
    // } else {
    //   setIsAuth(false);
    // }
  }

  return (
    <div className="flex flex-row gap-2">
      <label>Pseudo </label>
      <input
        className="border"
        type="text"
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      ></input>
      <label> Mot de passe </label>
      <input
        className="border"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></input>
      <button
        className="bg-blue-600 px-2 rounded text-white"
        onClick={handleClick}
      >
        Connexion
      </button>
    </div>
  );
};

export default Login;
