import { useEffect, useState } from "react";
import axios from "axios";
import qs from "qs";
import { OpenAI } from "openai";
import File from "./File";
import SaveModule from "./SaveModule";
import Login from "../Login";
import Logout from "../Logout";

import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

/*Liste des paramètres pour la réponse de Language Tools
 * data.matches : ensemble des mots avec erreurs
 * data.matches.offset : point de départ de l'erreur
 * data.matches.length : longueur de caractères de l'erreur
 * data.matches.rule.category.id : id du type d'erreur (CASING majuscule, AGREEMENT accord, TYPOS faute de frappe)
 *
 *
 *
 */
function Main() {
  const [text, setText] = useState();
  const [checkedText, setCheckedText] = useState(" ");
  const [isUpdatingText, setIsUpdatingText] = useState(false);
  const [isAltered, setIsAltered] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const [isAuth, setIsAuth] = useState(false); //Ce state est utilisé pour mettre à jour directement la page après la connexion.

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth(true);
    }
  }, []);

  //Fonction permettant de récupérer la date et de la mettre en forme en retournant une chaine de caractère
  function getCurrentDateTimeString() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Le mois est basé sur 0, donc on ajoute 1
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

    return formattedDateTime;
  }

  function translate(id, message) {
    //On ajoute le code d'erreur au message de base.
    //message = id + " : " + message;
    if (id === "J_N2") {
      message = "Accord dans le groupe nominal";
    }

    if (id === "ACCORD_SUJET_VERBE") {
      message = "Accord du verbe avec le sujet";
    }
    if (id === "UPPERCASE_SENTENCE_START") {
      message = "Il manque la majuscule au début de la phrase";
    }
    if (id === "D_N") {
      message = "Accord du déterminant et du nom";
    }
    if (id === "FR_SPELLING_RULE") {
      message = "Erreur dans le mot, regarde dans un lexique";
    }
    if (id === "ACCORD_R_PERS_VERBE") {
      message = "Accord du verbe et du sujet";
    }
    if ((id === "VIRG_NON_TROUVEE") | "PLACE_DE_LA_VIRGULE") {
      message = "Il manque une virgule";
    }
    if (id === "SE_CE") {
      message = "Erreur d'homonyme (se/ce)";
    }
    if (id === "SON_SONT") {
      message = "Erreur d'homonyme (son/sont)";
    }
    if (id === "DOUBLES_ESPACES") {
      message = "Il y a deux espaces";
    }
    if (id === "ETRE_VPPA_OU_ADJ") {
      message = "La chaine d'accord se poursuit avec le verbe être.";
    }
    if ((id === "A_INFINITIF") | "COMMENT_VPPA") {
      message = "Erreur de terminaison en [é]";
    }
    if (id === "PEUT_PEUX_PEU") {
      message = "Erreur d'homonyme (peux/peut/peu)";
    }
    if (id === "MOTS_INCOMP") {
      message = "Mot mal choisi ou mal écrit";
    }
    if (id === "MAIS_MES") {
      message = "Erreur d'homonyme (mais/mes)";
    }
    if (id === "YA") {
      message = "Cela s'écrit : y a";
    }
    if (id === "LA_LA2") {
      message = "Erreur d'homonyme (la/là)";
    }
    if (id === "APOS_M") {
      message = "Il faut mettre une apostrophe";
    }
    if (id === "AI_FR_HYDRA_LEO_MISSING_COMMA") {
      message = "Il manque une virgule quelque part dans ta phrase";
    }

    return message;
  }
  function correction(text, offset, length, typeError) {
    /* On décompose le texte en 3 parties. La partie 2 correspond au mot à surligner */
    let partOne = text.slice(0, offset);
    let partTwo = text.slice(offset, offset + length);
    let partThree = text.slice(offset + length, text.length);

    /* On renvoie le texte en ajoutant la balise <strong> autour du mot à souligner*/
    return (
      partOne +
      '<span class="popover_wrapper"><mark>' +
      partTwo +
      //Permet d'ajouter le type d'erreur dans le popup de correction

      '</mark><div class="popover">' +
      typeError +
      "</div></span>" +
      partThree
    );
  }

  function handleChange(event) {
    setText(event.target.value);
    if (!isAltered) {
      setIsAltered(true);
    }
  }

  //Fonction permettant de récupérer l'évenement de validation du formulaire
  function handleSubmit(event) {
    /*Permet d'empêcher le comportement par défaut du composant de validation du formulaire*/
    event.preventDefault();

    axios({
      method: "post",
      url: "https://api.languagetool.org/v2/check",
      headers: {
        origin: "*",
        Accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        language: "fr",
        text: text,
      }),
    })
      .then((response) => {
        let textChecked = text;
        let gap = 0;
        console.log(response.data);
        response.data.matches.map((match) => {
          let initialLength = textChecked.length;
          //Affichage du texte avec l'id du code d'erreur
          textChecked = correction(
            textChecked,
            match.offset + gap,
            match.length,
            translate(match.rule.id, match.rule.description)
          );

          gap = gap + (textChecked.length - initialLength);
        });

        setCheckedText(textChecked);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <p>
        Pour récupérer un texte que tu as enregistré sur ton ordinateur, clique
        sur le bouton "Parcourir..." et sélectionne ton texte. Pour enregitrer
        ton texte sur l'ordinateur, clique sur le bouton "Enregistrer". N'oublie
        pas de lui donner un nom.
      </p>

      {/* <File
        textToSave={text}
        setText={handleText}
        getCurrentDateTimeString={getCurrentDateTimeString}
      ></File> */}
      {!isAuth && <Login setIsAuth={setIsAuth} />}
      {isAuth && (
        <div>
          <Logout setIsAuth={setIsAuth} />
          <SaveModule
            text={text}
            setText={setText}
            getCurrentDateTimeString={getCurrentDateTimeString}
            setIsUpdatingText={setIsUpdatingText}
            isUpdatingText={isUpdatingText}
            isAltered={isAltered}
            setIsAltered={setIsAltered}
          />
        </div>
      )}
      <div className="main-content">
        <form className="form" onSubmit={handleSubmit}>
          <fieldset>
            <legend>Texte à corriger</legend>
            <textarea
              id="text"
              placeholder="Ecris ton texte ici"
              autoFocus
              value={text}
              onChange={handleChange}
            />
            <input type="submit" value="Corriger" />
          </fieldset>
        </form>
        <div>
          <h2>Correction</h2>
          <p dangerouslySetInnerHTML={{ __html: checkedText }}></p>
        </div>
      </div>

      <div className="description">
        <p>
          Ecris ton texte dans l'espace ci-dessus puis clique sur le bouton
          "Corriger". Tu verras alors toutes les erreurs s'afficher à droite
          (elles seront surlignées en jaune). En passant ton curseur dessus, tu
          auras un indice sur la correction à apporter. Tu devras alors corriger
          ton texte et relancer une correction jusqu'à ce qu'il n'y ait plus
          d'erreurs affichées.
        </p>
      </div>
    </div>
  );
}
export default Main;
