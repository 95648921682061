import { useEffect, useState } from "react";
import { useDatabase } from "../service/data.service";

const SaveModule = ({
  text,
  getCurrentDateTimeString,
  setText,
  setIsUpdatingText,
  isUpdatingText,
  isAltered,
  setIsAltered,
}) => {
  const [title, setTitle] = useState("Sans Titre");
  const [arrayOfTexts, setArrayOfTexts] = useState([]);
  const [content, setContent] = useState("");
  const [currentText, setCurrentText] = useState(""); //Id du texte courant
  const [currentStatus, setCurrentStatus] = useState(""); //Status du texte
  const [refresh, setRefresh] = useState(0);

  const { saveText, getTextsById, updateText } = useDatabase();
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTextsById();
        if (response.ok) {
          try {
            const data = await response.json();
            setArrayOfTexts(data);
          } catch (error) {}
        } else {
          console.log("Erreur : ", response);
        }
        // console.log(data);
      } catch (error) {}
    }
    fetchData();
  }, [refresh]);

  function handleChange(e) {
    setTitle(e.target.value);
    if (!isAltered) {
      setIsAltered(true);
    }
  }
  // function handleClick() {
  //   const data = JSON.stringify({
  //     title: title + "-" + getCurrentDateTimeString(),
  //     text: text,
  //   });
  //   console.log(data);
  //   fetch("https://back-correcteur.classenco.fr/api/saveData", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: data, // text est votre objet contenant les données à sauvegarder
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("Data saved:", data);
  //     })
  //     .catch((error) => {
  //       console.error("Error saving data:", error);
  //     });
  // }

  // function handleClick() {
  //   fetch("http://back-app-spell-check.classenco.fr/api/auth/signup", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: data,
  //   }).then((response) => {
  //     console.log("réponse du serveur", response);
  //   });
  // }

  async function handleClick() {
    setIsAltered(false);
    if (isUpdatingText) {
      try {
        const response = await updateText(
          currentText,
          title,
          text,
          currentStatus
        );
        setRefresh(refresh + 1);
      } catch (error) {}
      // setTimeout(setRefresh(refresh + 1), 1000);
    } else {
      try {
        const resonse = await saveText(title, text);
        setRefresh(refresh + 1);
        setIsUpdatingText(true);
      } catch (error) {}
      // setTimeout(setRefresh(refresh + 1), 1000);
    }
  }
  async function changeStatus() {
    if (currentStatus === "draft") {
      try {
        const response = await updateText(
          currentText,
          title,
          text,
          "submitted"
        );
        setRefresh(refresh + 1);
        setCurrentStatus("submitted");
      } catch (error) {}
      // setTimeout(setRefresh(refresh + 1), 1000);
    }
    if (currentStatus === "submitted") {
      try {
        const response = await updateText(currentText, title, text, "draft");
        setRefresh(refresh + 1);
        setCurrentStatus("draft");
      } catch (error) {}
      // setTimeout(setRefresh(refresh + 1), 1000);
    }
  }

  function setColorStatus(status) {
    const basicStyle = "rounded px-2 font-bold text-slate-100 ";
    switch (status) {
      case "draft":
        return basicStyle + "bg-red-500";
      case "submitted":
        return basicStyle + "bg-orange-500";
      case "verified":
        return basicStyle + "bg-green-500";
      case "published":
        return basicStyle + "bg-blue-500";
    }
  }

  return (
    <div>
      <h3 className="underline decoration-blue-500 decoration-4 py-4 text-xl">
        Liste des Textes enregistrés
      </h3>

      <div className="grid grid-cols-6 gap-1">
        <div>
          <button
            className="bg-blue-800 px-2 rounded text-white font-bold"
            onClick={() => {
              setText("");
              setCurrentText("");
              setIsUpdatingText(false);
              setCurrentStatus("draft");
              setTitle("Sans nom");
            }}
          >
            Nouveau
          </button>
        </div>
        {arrayOfTexts.map((item) => (
          <div key={item.t_id}>
            <button
              className={setColorStatus(item.t_status)}
              onClick={() => {
                setText(item.t_content);
                setCurrentText(item.t_id);
                setIsUpdatingText(true);
                setCurrentStatus(item.t_status);
                setTitle(item.t_title);
              }}
            >
              {item.t_title}
            </button>{" "}
          </div>
        ))}
      </div>
      <div className="pt-5 px-2 flex gap-2">
        <input
          type="text"
          className="border rounded"
          value={title}
          onChange={handleChange}
        ></input>
        <button
          className="rounded bg-blue-500 text-white font-bold px-2"
          onClick={handleClick}
        >
          Enregistrement du texte {isAltered && "*"}
        </button>
        <button
          className="rounded bg-blue-500 text-white font-bold px-2"
          onClick={changeStatus}
        >
          {currentStatus === "draft" ? "soumettre" : "Brouillon"}
        </button>
      </div>
    </div>
  );
};

export default SaveModule;
