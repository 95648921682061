import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignIn from "react-auth-kit/hooks/useSignIn";
//require("dotenv").config();

export function useDatabase() {
  const signIn = useSignIn();
  const back_address = process.env.REACT_APP_BACK_ADDRESS;
  const authHeader = useAuthHeader();
  const auth = useAuthUser();

  function login(username, password) {
    return fetch(`${back_address}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
  }

  function saveText(title, content) {
    console.log("AuthHeader :", authHeader);
    return fetch(`${back_address}/api/text/`, {
      method: "POST",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        content: content,
      }),
    }).then((response) => {});
  }
  function updateText(id, title, content, status) {
    return fetch(`${back_address}/api/text/${id}`, {
      method: "PUT",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        content: content,
        status: status,
      }),
    }).then((response) => {});
  }

  function getTextsById() {
    return fetch(`${back_address}/api/text/user/${auth.user_id}`, {
      method: "GET",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
    });
    // .then((response) => {
    //   if (response.ok) {
    //     response.json();
    //   } else {

    //   }
    // })
    // .catch((error) => {
    //   error.json();
    // });
  }

  function addUser(username, password) {
    return fetch(`${back_address}/api/auth/signup`, {
      method: "POST",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    }).then((response) => {});
  }

  function getUsers() {
    return fetch(`${back_address}/api/user`, {
      method: "GET",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }
  function getTexts() {
    return fetch(`${back_address}/api/text`, {
      method: "GET",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }
  function deleteUser(id) {
    return fetch(`${back_address}/api/user/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  return {
    login,
    saveText,
    updateText,
    getTextsById,
    addUser,
    getUsers,
    getTexts,
    deleteUser,
  };
}
