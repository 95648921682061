import React from "react";
import useSignOut from "react-auth-kit/hooks/useSignOut";

const Logout = ({ setIsAuth }) => {
  const signOut = useSignOut();
  function handleClick() {
    signOut();
    setIsAuth(false);
    window.location.reload();
  }
  return (
    <div>
      <button
        className="rounded px-2 font-bold text-white bg-blue-500"
        onClick={handleClick}
      >
        Déconnexion
      </button>
    </div>
  );
};

export default Logout;
