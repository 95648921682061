import "./App.css";
import Main from "./Main";

function App({ children }) {
  return (
    <div className="app-layout">
      <header className="app-header">
        <h1 className="header-title">Le Correcteur</h1>
      </header>
      <main className="app-content">{children} </main>
      <footer className="app-footer">
        <p>
          Outil conçu par <a href="http://blog.classenco.fr">classenco</a>. Il
          utilise le correcteur fourni par{" "}
          <a href="https://languagetool.org/fr">languagetool.org</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
