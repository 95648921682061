import React, { useEffect, useState } from "react";
import { useDatabase } from "../service/data.service"; //Hook permettant d'utiliser le service DATABASE de l'application
import Login from "../Login"; //Composant Login pour l'utlisateur
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Admin = () => {
  const isAuthenticated = useIsAuthenticated(); //Permet de récupérer depuis le hook d'authentification l'état de l'utilisateur (connecté ou non)
  const auth = useAuthUser();
  const [newUsername, setNewUsername] = useState(""); //State pour créer un nouvel élève : nom d'utilisateur
  const [newUserpassword, setNewUserpassword] = useState(""); //State pour créer un nouvel élève : mot de passe
  const [arrayOfUsers, setArrayOfUsers] = useState([]); //State pour stocker le résutlat de la requête récupérant la liste des utilisateurs enregistrés.
  const [isAuth, setIsAuth] = useState(false); //Ce state est utilisé pour mettre à jour directement la page après la connexion.
  const [refreshUsers, setRefreshUsers] = useState(0); // State utiliser pour mettre à jouer la liste d'utilisateurs suite à un changement dans la database
  const [refreshTexts, setRefreshTexts] = useState(0); // State utiliser pour mettre à jouer la liste des textes suite à un changement dans la database
  const [arrayOfTexts, setArrayOfTexts] = useState([]); //State utiliser pour stocker l'ensemble des textes de la database
  const [currentText, setCurrentText] = useState(""); // State stockant le texte à afficher dans la page admin
  const [filteredArrayOfTexts, setFilteredArrayOfTexts] = useState([]); //State stockant le tableau de texte après application du filtre
  const [isMounted, setIsMounted] = useState(false); //State permettant de stocker l'état du composant (déjà chargé ou non).
  const [currentFilter, setCurrentFilter] = useState(""); //State permettant de stocker le filtre courant
  const { addUser, getUsers, getTexts, updateText, deleteUser } = useDatabase(); //Permet de récupérer les fonctions du hook UseDatabase
  //Récupération de la liste des utilisateurs au chargement du composant et à chaque changement sur le stats refreshUsers
  useEffect(() => {
    getUsers().then((data) => {
      setArrayOfUsers(data.filter((user) => user.u_id !== auth.user_id));
    });
  }, [refreshUsers]);
  //Récupération de la liste des textes au chargement du composant et à chaque changement du state refreshTexts
  useEffect(() => {
    getTexts().then((data) => {
      setArrayOfTexts(data);
      if (currentFilter !== "") {
        setFilteredArrayOfTexts(
          data.filter((text) => text.t_status === currentFilter)
        );
      } else {
        setFilteredArrayOfTexts(data);
      }
    });
  }, [refreshTexts]);
  //Permet de déclarer le state isAuth à true ou false au chargement du composant.
  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth(true);
    }
  }, []);
  //Fonction permettant, à chaque clique, de faire avancer le statut du texte d'un cran.
  function updateStatus(text) {
    switch (text.t_status) {
      case "draft":
        updateText(text.t_id, text.t_title, text.t_content, "submitted").then(
          (response) => setRefreshTexts(refreshTexts + 1)
        );
        break;
      case "submitted":
        updateText(text.t_id, text.t_title, text.t_content, "verified").then(
          (response) => setRefreshTexts(refreshTexts + 1)
        );
        // setRefreshTexts(refreshTexts + 1);
        break;
      case "verified":
        updateText(text.t_id, text.t_title, text.t_content, "published").then(
          (response) => setRefreshTexts(refreshTexts + 1)
        );
        // setRefreshTexts(refreshTexts + 1);
        break;
      case "published":
        updateText(text.t_id, text.t_title, text.t_content, "draft").then(
          (response) => setRefreshTexts(refreshTexts + 1)
        );
        // setRefreshTexts(refreshTexts + 1);
        break;
    }
  }

  /**Fonction permettant de fixer la couleur d'un élément lié à un texte en fonction du statut du texte */
  function setColorStatus(status) {
    const basicStyle = "rounded px-2 font-bold text-slate-100 ";
    switch (status) {
      case "draft":
        return basicStyle + "bg-red-500";
      case "submitted":
        return basicStyle + "bg-orange-500";
      case "verified":
        return basicStyle + "bg-green-500";
      case "published":
        return basicStyle + "bg-blue-500";
    }
  }

  return (
    <div>
      {!isAuth && <Login setIsAuth={setIsAuth} />}
      {isAuth && (
        <div>
          <div className="flex flex-row justify-center gap-5">
            <div>
              <h3 className="text-xl font-bold underline decoration-4 decoration-blue-950 text-blue-950 p-4">
                Liste des élèves
              </h3>
              <ul className="py-4">
                {arrayOfUsers.map((user) => (
                  <li
                    key={"user" + user.u_id}
                    className="my-2 font-semibold grid grid-cols-3 gap-1"
                  >
                    {user.u_pseudo}
                    <button className="rounded bg-blue-600 text-white">
                      Réinit. mot de passe
                    </button>
                    <button
                      className="rounded bg-red-600 text-white"
                      onClick={() => {
                        deleteUser(user.u_id);
                        setRefreshUsers(refreshUsers + 1);
                      }}
                    >
                      Supprimer
                    </button>
                  </li>
                ))}
                <li className="flex gap-2">
                  <input
                    placeholder="Nom d'utilisateur"
                    className="border-2"
                    value={newUsername}
                    onChange={(e) => {
                      setNewUsername(e.target.value);
                    }}
                  ></input>
                  <input
                    placeholder="Mot de passe"
                    className="border-2"
                    value={newUserpassword}
                    onChange={(e) => {
                      setNewUserpassword(e.target.value);
                    }}
                  ></input>
                  <button
                    className="bg-blue-950 text-white rounded px-2"
                    onClick={() => {
                      addUser(newUsername, newUserpassword);
                      setRefreshUsers(refreshUsers + 1);
                      setNewUsername("");
                      setNewUserpassword("");
                    }}
                  >
                    Ajouter
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <div className="flex gap-4">
                <h3 className="text-xl font-bold underline decoration-4 decoration-blue-950 text-blue-950 p-4">
                  Liste des textes
                </h3>
                <span className="">
                  <button
                    className={setColorStatus("draft")}
                    onClick={() => {
                      var filteredArray = arrayOfTexts.filter(
                        (text) => text.t_status === "draft"
                      );
                      setFilteredArrayOfTexts(filteredArray);
                      setCurrentFilter("draft");
                    }}
                  >
                    Brouillon
                  </button>
                  <button
                    className={setColorStatus("verified")}
                    onClick={() => {
                      var filteredArray = arrayOfTexts.filter(
                        (text) => text.t_status === "verified"
                      );
                      setFilteredArrayOfTexts(filteredArray);
                      setCurrentFilter("verified");
                    }}
                  >
                    Vérifié
                  </button>
                  <button
                    className={setColorStatus("submitted")}
                    onClick={() => {
                      var filteredArray = arrayOfTexts.filter(
                        (text) => text.t_status === "submitted"
                      );
                      setFilteredArrayOfTexts(filteredArray);
                      setCurrentFilter("submitted");
                    }}
                  >
                    Soumis
                  </button>
                  <button
                    className={setColorStatus("published")}
                    onClick={() => {
                      var filteredArray = arrayOfTexts.filter(
                        (text) => text.t_status === "published"
                      );
                      setFilteredArrayOfTexts(filteredArray);
                      setCurrentFilter("published");
                    }}
                  >
                    Publié
                  </button>
                  <button
                    className="rounded px-2 font-bold text-slate-100 bg-slate-500"
                    onClick={() => {
                      var filteredArray = arrayOfTexts;
                      setFilteredArrayOfTexts(filteredArray);
                      setCurrentFilter("");
                    }}
                  >
                    RAZ filtres
                  </button>
                </span>
              </div>
              <ul className="grid grid-cols-4 gap-4">
                {filteredArrayOfTexts.map((text) => (
                  <li
                    key={"text" + text.t_id}
                    className={setColorStatus(text.t_status)}
                    onClick={() => {
                      setCurrentText(text.t_content);
                    }}
                  >
                    <span className="flex justify-between  cursor-default">
                      <span>{text.t_title}</span>
                      <button
                        onClick={() => {
                          updateStatus(text);
                        }}
                        className="text-gray-700 font-thin text-sm"
                      >
                        {text.t_status}
                      </button>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>{currentText}</div>
        </div>
      )}
    </div>
  );
};

export default Admin;
